<template>
  <div>
    <!-- 轮播部分 -->
    <div class="swiper-container" v-if="banners.length != 0">
      <el-carousel trigger="click" height="670px" :autoplay="true" >
        <el-carousel-item v-for="(item, key) in banners" :key="key">
          <ul>
            <li>
              <!-- <span> -->
              <img :src="item.tj_images" alt=""  @click="skipDetails(item)"/>

              <!-- </span> -->
            </li>
          </ul>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!--<ul>
            <li>1
                <span></span>
            </li>
        </ul> -->
      <!-- 主体 -->
    <div class="hotlist"  :class="Scales ? 'Scales' : ''">
      <!-- 博物活动 -->
      <div class="title">
        <!-- <img src="../assets/img/" alt=""> -->
        <div class="center">
          <img src="../assets/left.png" alt="" />
          <div class="span">博物活动</div>
          <img src="../assets/right.png" alt="" />
        </div>
        <div class="outRight" @click="more('活动')">更多+</div>
      </div>
      <div class="list">
    <div class="img">    
      <transition name="el-fade-in">
        <div v-show="show" class="transition-box">
          
            <img :src="activyImg" @click="skipImgs(skipImg)" alt="" />
          
        </div>
        
      </transition>
      <transition name="el-fade-in">
        <div v-show="show1" class="transition-box">
            <img :src="activyImg1" @click="skipImgs(skipImg)" alt="" />
          
        </div>
      </transition>
      </div>
        <div class="detail">
          <div>
              <ul class="Libiao">
                <div v-for="(item, key) in list" :key="key" :class="(key+1)%5 ==0 ? '' : 'hotBorderOne'">
                  <li >
                    <div><span  
                    @mousedown.stop="an" 
                  @mouseup.up="song(item)"
                  @mouseover="mouseovers(item)"
                    >{{ item.name }}</span> </div>
                    <!-- {{key}}  {{}} -->
                    <div class="deta">  {{ item.create_time | time }} </div>
                    <img src="../assets/museumDetails/mark.png" alt="" class="biao" />

                    
                  </li>
                </div>
              </ul>
          </div>
          
          <div class="pagetion">
            <div class="page">
              <div class="left" @click="top">
                <img src="../assets/museumDetails/left.png" alt="" />
              </div>
              <div
                v-for="(item, key) in page"
                @click="pages(item)"
                :key="key"
                class="size"
                :class="actives == key + 1 ? 'actives' : 'noactives'"
              >
                {{ key + 1 }}
              </div>
              <div class="ri" @click="bottom">
                <img src="../assets/museumDetails/right.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 艺术展览 -->
      <div class="title">
        <!-- <img src="../assets/img/" alt=""> -->
        <div class="center">
          <img src="../assets/left.png" alt="" />
          <div class="span">艺术展览</div>
          <img src="../assets/right.png" alt="" />
        </div>
        <div class="outRight" @click="more('展览')">更多+</div>
      </div>
      <div class="imageList">
        <div
          class="imgs"
          v-for="(item, key) in outList"
         :key="key"
         @click="skipDetails(item)"
        >
          <img
            :src="require('../assets/img/book/' + item.ceimg)"
            alt=""
            class="leftImg"
          />
          <img :src="item.images"   alt="" class="big rightImg"/>
          <div class="leftImgText">
            <div class="detailTitle"
            >
              {{ item.introduce }}
            </div>
            <div class="titleName"
            >
              <!-- <router-link tag="span" to="/"> -->
                {{ item.name }}
              <!-- </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <Footer></Footer>
  </div>
</template>
<script>
// import { Swiper } from '@/style/swiper-bundle.js'
import { fetch } from "../api/https";
import Footer from "../components/footer";
import moment from 'moment'
import $ from "../components/index-page/jquery-3.3.1.min.js";
export default {
  data() {
    return {
      page: Number,
      list: [],
      actives: 1,
      outList: [],
      swiperImage: [],
      banners: [],
            timeLength:0,
      oldtime:0,
      activyImg:'',
      show:true,
      show1:false,
      activyImg1:'',
      Scales:false,
      skipImg:""
    };
  },
  filters:{
    time(num){
      return moment(1000*parseInt(num)).format("YYYY-MM-DD")
    }
  },
  mounted(){
    
    var width = document.documentElement.clientWidth;
        var height = document.documentElement.clientHeight;
        
        if(width>1024 &&  width< 1400){
          this.Scales = true
        }
           
  },
  methods: {
    
    mouseovers(item){
      this.skipImg = item;
      if(this.show){
        if(item.images ==this.activyImg){
        return false
        }else{
        this.show = false
        // var intovel = setInterval(function(){
          var that = this
          setTimeout(function(){
          that.show1 = true
            
          },500)

        // },500)
        // intovel
        this.activyImg1 = item.images
        }
      }else if(!this.show){
        if(item.images ==this.activyImg1){
        return false
        }else{
        this.show1 = false
        var that = this
          setTimeout(function(){
          that.show = true
            
          },301)
        this.activyImg = item.images  
        }
      }
      
          
    },
    an(){ //鼠标按下
       this.timeLength = new Date().getTime()
    },
    song(item){ //鼠标松开 判断鼠标点击的时间
       this.oldtime = new Date().getTime()
       if((this.oldtime-this.timeLength) < 130){
      this.$router.push('activitydetail?id='+item.id)
       }
       
    },
        skipImgs(item){ //鼠标松开 判断鼠标点击的时间
      this.$router.push('activitydetail?id='+this.skipImg.id)
  
       
    },
    liBtn(item){
    },
    skip(item) {
    },
    pages(val) {
      this.actives = val;
      this.requst();
    },
    bottom() {
      this.actives++;
      if (this.actives > this.page) {
        this.actives = 1;
      }
      this.requst();
    },
    top() {
      this.actives--;
      if (this.actives < 1) {
        this.actives = this.page;
      }
      this.requst();
    },
    skipDetails(item) {
            // if((this.oldtime-this.timeLength) < 130){
              this.$router.push(`/Introduction?id=${item.id}`);
            // }
    },
    //专题活动请求
    requst() {
      fetch("/activity_list", { cate_id: 1, page: this.actives, pagesize: 5 })
        .then((res) => {
          this.page = res.data.total_page;
          this.list = res.data.data;
          this.activyImg = this.list[0].images
          this.skipImg = this.list[0];
        })
        .catch((err) => {});
    },
    requstOut() {
      fetch("/activity_list", { cate_id: 2, page: 1, pagesize: 8 })
        .then((res) => {
          this.outList = res.data.data;
          this.outList.forEach((item, key) => {
            var str = `${key + 1}.png`;
            item.ceimg = str;
          });
          this.outList = this.outList.splice(0, 4);
        })
        .catch((err) => {});
    },
    swiperImg() {
      fetch("/activity_list", { tj_status: 2 }).then((res) => {
        this.banners = res.data.data;
      });
    },
    more(val) {
      if (val == "活动") {
        this.$router.push("/hotList");
      }
      if (val == "展览") {
        this.$router.push("/zhan");
      }
    },
  },

  mounted() {
    this.requst();
    this.requstOut();
    this.swiperImg();
    fetch('/getmenu')
            .then(res => {
              document.title = res.data[5].menu_seo_title
              
            })
  },
  created() {
    // this._initSwiper();
    // var swiper = new Swiper('.swiper-container');
  },
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.hotlist {
  max-width: 1400px;
  height: auto;
  margin: 0 auto;
  position: relative;
}
.title {
  text-align: center;
  height: 30px;
  margin: 0 auto;
  // width: 100%;
  font-size: 24px;
  color: #333333;
  padding-top: 62px;
  padding-bottom: 30px;
  
  position: relative;
  font-weight: bold;
  margin: 0 auto;

  img {
    width: 60px;
    height: 28px;
    float: left;
    margin-top: 3px;
    margin: 0 10px;
    height: 30px;
  }
  .center {
    margin: 0 auto;
    width: 280px;
    .span {
      float: left;
    }
  }
}
.outRight {
  position: absolute;
  right: 0;
  top: 62px;
  font-size: 16px;
  font-weight: initial;
  cursor: pointer;
  color: #666666;
  line-height: 32.5px;
}
.list {
  background: url("../assets/museumDetails/wxt.jpg");
  background-size: contain;
  max-width: 1400px;
  height: 467px;
  .img {
    z-index: 10000;
    width: 700px;
    height: 100%;
    float: left;
    overflow: hidden;
  }
  .img img {
    height: 100%;
    width: 700px;
    transition: 1s;
  }
  .img img:hover{
    transform: scale(1.1);
  }
}
.detail {
  width: 700px;
  float: right;
  position: relative;
  height: 467px;
  box-shadow: 1px 0px 5px #cccccc;
}

.Libiao {
  width: 700px;

  box-sizing: border-box;
  padding-left: 27px;
  padding-right: 31px;
  padding-top: 30px;
  li {
    box-sizing: border-box;
    padding-left: 40px;
    width: 100%;
    margin: 0 auto;
    height: 71px;
    position: relative;
    // padding-right: 10px;
    line-height: 70px;
    font-family:'微软雅黑';
    font-size: 16px;
    padding-right: 6px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    .xu {
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0%;
    }
    span:hover{
      color: #c19c66 !important;
      cursor: pointer;
    }
    .biao {
      width: 17px;
      height: 14px;
      position: absolute;
      top: 28px;
      left: 6px;
    }
  }
}
.Scales{
  transform: scaleX(0.93);
}
.deta {
  position: absolute;
  line-height: 70px;
  right: 10px;
  top: 0;
  font-size: 14px;
}
.page {
  margin: 0 auto;
  display: inline-block;
  margin-top: 30px;
  .size {
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    line-height: 36px;
    font-size: 15px;
    float: left;
    margin: 0 10px;
  }
  //   width: auto;
  .left {
    cursor: pointer;
    float: left;
    img {
      width: 20px;
      height: 32px;
      margin: 0 20px;
    }
  }
  .ri {
    cursor: pointer;
    float: right;
    img {
      width: 20px;
      height: 32px;
      margin: 0 20px;
    }
  }
}
.pagetion {
  width: 700px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 23px;
  left: 0;
  box-sizing: border-box;
}
.actives {
  background: rgb(208,119, 72);
    color: white;
}
.noactives{
    color: #666666;

}
.imageList {
  width: 1400px;
  height: auto;
  //   background-color: yellow;
  display: flex;
  flex-wrap: wrap;
  .imgs {
    width: 328px;
    height: 449px;
    position: relative;
    margin-bottom: 61px;
      transition: 1s;
    overflow: hidden;
    margin-right: 20px;
    img {
      height: 449px;
    }
    
    .big {
      position: absolute;
      top: 0;
      right: 0;
      width: 198px;
      height: 100%;
    }
    .leftImg {
      width: 130px;
      height: 100%;
      float: left;
    }
  }
  .imgs:hover{
      transform: scale(1.1);
    }
}
.leftImgText {
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  height: 100%;
  color:rgb(255, 255, 255);
  box-sizing: border-box;
  padding-top: 30px;
  padding-left: 35px;
font-weight:500;
  padding-right: 35px;
}
.titleName {
  font-size: 22px;
  width: 25px;
  float: right;
  font-family:'微软雅黑';
 }
.detailTitle {
  width: 20px;
  float: left;
  font-size: 16px;
font-weight: 350;
  color:rgb(255, 255, 255);
  // font-size:'MicrosoftYaHeiLight';
   font-family:'微软雅黑';
}
.swiper-container {
  width:100%;
  height:670px;
  img {
  height:670px;
    width: 100%;
  }
}
.hotBorderOne{
  // height: 1px;
  border-bottom: 1px solid #F4F4F4;
}
.borderNone {
  border: none;
}
.border {
  border-bottom: 1px solid rgba($color: #fbfdff, $alpha: 0.2);
  // opacity: 0.2;
}
/deep/ .is-active {
  background: url("../assets/museumIntro/activeLogo.png") center center no-repeat;
}
/deep/ .el-carousel__indicator--horizontal {
  padding: 0;
  margin: 30px 11.5px;
}
/deep/.el-carousel__button {
  opacity: 1;
  width: 32px;
  height: 31px;
  background: none;
  background: url("../assets/museumIntro/logobiao.png") center center no-repeat;
}
/deep/.el-carousel__indicator:hover button {
  opacity: 1;
}
/deep/.el-carousel__indicator.is-active button {
  opacity: -1;
}
/deep/ .is-active {
  background: url("../assets/museumIntro/activeLogo.png") center center no-repeat;
}
/deep/.el-carousel__arrow{
  width: 80px;
  height: 80px;
}
/deep/.el-carousel__arrow {
    border: none;
    height: 80px;
    width: 80px;
    cursor: pointer;
    transition: .3s;
    border-radius: 50%;
    background-color: rgba(31,45,61,.3);
    color: #FFF;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-70%);
    text-align: center;
    font-size: 40px;
}
/deep/.el-carousel__arrow--left {
    left: 40px;
}
/deep/.el-carousel__arrow--right {
    right: 40px;
}
/deep/.el-fade-in-enter-active{
  transition: all .5s;
}
@media screen  and (max-width: 1400px){
  .list{
    width: 1200px;
  }
  .pagetion{
    width:650px;
  }
  // .details{
  //   padding: 0 ;
  // }
    .title {
      min-width: 1200px;
    max-width: 1266px;
  text-align: center;
  height: 30px;
  margin-top: 0 auto;
  // width: 100%;
  font-size: 24px;
  color: #333333;
  margin-top: 62px;
  margin-bottom: 30px;
  position: relative;
  font-weight: bold;
  padding: 0;
  img {
    width: 60px;
    height: 28px;
    float: left;
    margin-top: 3px;
    margin: 0 10px;
    height: 30px;
  }
  .center {
    margin: 0 auto;
    width: 280px;
    .span {
      float: left;
    
  }
}
  }
  .list {
  background: url("../assets/museumDetails/wxt.jpg");
  background-size: contain;
  max-width: 1400px !important;
  min-width: 1266px !important;
  height: 467px;
  margin: 0 auto;
  .img {
    z-index: 10000;
    width: 633px;
    height: 100%;
    float: left;
    overflow: hidden;
  }
  .img img {
    height: 100%;
    width: 633px;
    height: 468px;
    transition: 1s;
  }
  .img img:hover{
    transform: scale(1.1);
  }
  .Libiao{
    width: 633px;
  }
  .detail{
    width: 633px;
  } 

}
.imageList{
    width: 1266px !important;
    margin: 0 auto;
  }
  .imgs {
    width: 300px !important;
    height: 449px;
    position: relative;
    margin-bottom: 61px;
      transition: 1s;
    overflow: hidden;
    img {
      height: 449px;
    }
    
    .big {
      position: absolute;
      top: 0;
      right: 0;
      width: 184px !important;
      height: 100%;
    }
    .leftImg {
      width: 144px !important;
      height: 100%;
      float: left;
    }
  }
    .outRight {
  position: absolute;
  right: 0;
  top: 0 !important;
  font-size: 16px;
  font-weight: initial;
  cursor: pointer;
  color: #666666;
  line-height: 32.5px;
}
}
</style>
<style>

</style>